import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

(async () => {
  try {
    const config = await fetch(`/config/${window.location.hostname}.json`)
      .then(r => r.ok ? r.json() : fetch(`/config/default.json`).then(r => r.json()));

    window.ReactAppEnv = config;
    window.ReactAppEnv.version = import.meta.env.VITE_BUILD_VERSION;
    const LDProvider = await asyncWithLDProvider(config.featureFlagConfig);

    const { App, createHistory, Router } = await import('./AppPreload');
    const history = createHistory();

    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
      <Router history={history}>
        <LDProvider>
          <App />
        </LDProvider>
      </Router>
    );
  } catch (e) {
    console.error('Unable to download app environment config.');
    console.error(e);
  }
})();
